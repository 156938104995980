import { defineStore } from 'pinia'
export const useAccoStore = defineStore({
  id: 'accoStore',
  state: () => ({
    accos: <Acco[]>[],
    arrivalDate: <Date | null>null,
    departureDate: <Date | null>null,
    from: 0,
    perPage: 10,
    totalResults: -1,
    markers: <Marker[]>[],
    update: false,
    view: 'list',
    adults: 0,
    children: 0,
    babies: 0,
    pets: 0,
    minBedrooms: 0,
    minBathrooms: 0,
    minStars: 0,
    minRating: 0,
    noPets: false,
    companyVfy: false,
    priceMin: 0,
    priceMax: 3500,
    showLastMinutes: false,
    pricerange: {
      min: 0,
      max: 3500,
      type: 'night'
    } as PriceRange,
    sauna: false,
    hottub: false,
    spa: false,
    pool: false,
    privatpool: false,
    garden: false,
    gardenEnclosed: false,
    terrace: false,
    balcony: false,
    parking: false,
    wifi: false,
    washingMachine: false,
    stove: false,
    tumbleDryer: false,
    airco: false,
    tv: false,
    eCarCharging: false,
    dishwasher: false,
    oven: false,
    bbq: false,
    childrenBed: false,
    childrenChair: false,
    detached: false,
    villa: false,
    mansion: false,
    castle: false,
    chalet: false,
    farmhouse: false,
    bungalow: false,
    holidayHome: false,
    apartment: false,
    penthouse: false,
    glampingTent: false,
    houseboat: false,
    nearSkiSlope: false,
    nearSea: false,
    nearLake: false,
    onHolidayPark: false,
    nearSkibus: false,
    maxKmFromSkilift: 0,
    maxKmFromGolfCourse: 0,
    searchCenter: {
      lat: 0,
      lng: 0
    } as LatLng,
    searchBounds: {
      south: 0,
      west: 0,
      north: 0,
      east: 0
    } as Bounds,
    paginationAction: false,
    diffDays: 3,
    saveBoundsInUrlParams: false,
    zoomLevel: 0,
    useStrictBounds: false,
    openFilters: false
  }),
  actions: {
    newAccos(newAccos: Acco[]) {
      this.accos = newAccos
      if (import.meta.client) {
        setTimeout(() => {
          document?.getElementById('lister')?.scrollIntoView({ behavior: 'smooth' })
        }, 1000)
      }
    },
    clearAccos() {
      this.accos = []
      // this.totalResults = 0 mag niet ivm pagination
    },
    resetPagination() {
      this.from = 0
      this.totalResults = 0
    },
    setTotalResults(total:number) {
      this.totalResults = total
    },
    setUpdate(state: boolean) {
      this.update = state
    },
    setView(view: string) {
      this.view = view
      if (view === 'list') {
        this.searchBounds = {
          south: 34.82202822889615,
          west: -19.187923124999998,
          north: 58.567747676682366,
          east: 30.909733125
        }
      }
    },
    setPriceRange(priceRange: PriceRange) {
      this.pricerange = priceRange
    },
    setSearchCenter(latLng: LatLng) {
      this.searchCenter = latLng
    },
    setSearchBounds(bounds: Bounds) {
      this.searchBounds = bounds
    },
    setMarkers(markers: Marker[]) {
      this.markers = markers
    },
    setPaginationAction(state: boolean) {
      this.paginationAction = state
    },
    setDiffDays(days: number) {
      this.diffDays = days
    },
    setSaveBoundsInUrlParams(state: boolean) {
      this.saveBoundsInUrlParams = state
    },
    setZoomLevel(zoom: number) {
      this.zoomLevel = zoom
    },
    setUseStrictBounds() {
      this.useStrictBounds = true
      setTimeout(() => {
        this.useStrictBounds = false
      }, 3000)
    },
    reset() {
      this.arrivalDate = null
      this.departureDate = null
      this.priceMin = 0
      this.priceMax = 3500
      this.pricerange.min = 0
      this.pricerange.max = 3500
      this.adults = 0
      this.children = 0
      this.babies = 0
      this.pets = 0
      this.minBedrooms = 0
      this.minBathrooms = 0
      this.minStars = 0
      this.minRating = 0
      this.noPets = false
      this.showLastMinutes = false
      this.companyVfy = false
      this.sauna = false
      this.hottub = false
      this.spa = false
      this.pool = false
      this.privatpool = false
      this.garden = false
      this.gardenEnclosed = false
      this.terrace = false
      this.balcony = false
      this.parking = false
      this.wifi = false
      this.washingMachine = false
      this.stove = false
      this.tumbleDryer = false
      this.airco = false
      this.tv = false
      this.eCarCharging = false
      this.dishwasher = false
      this.oven = false
      this.bbq = false
      this.childrenBed = false
      this.childrenChair = false
      this.detached = false
      this.villa = false
      this.mansion = false
      this.castle = false
      this.chalet = false
      this.farmhouse = false
      this.bungalow = false
      this.holidayHome = false
      this.apartment = false
      this.penthouse = false
      this.glampingTent = false
      this.houseboat = false
      this.nearSkiSlope = false
      this.nearSea = false
      this.nearLake = false
      this.onHolidayPark = false
      this.nearSkibus = false
      this.maxKmFromSkilift = 0
      this.maxKmFromGolfCourse = 0
    }
  },
  getters: {
    totalAccos(): number {
      return this.accos.length
    }
  }
})
