import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccoSlider, LazyAccoSliderSearch, LazyAlpendreamsContact, LazyBecomeAPartner, LazyBreadcrumbs, LazyBtn, LazyContactInfo, LazyContactMessageUs, LazyContactMessageUsNEW, LazyFaqRich, LazyFaqs, LazyGoogleMaps, LazyGrid, LazyGroepshuizenGidsAanvragen, LazyHomeownerLeadFrom, LazyIframe, LazyImageGallery, LazyLinkBlocks, LazyNewsletterSubscribe, LazyOurOffices, LazyOurPartners, LazyPage, LazyPageHeader, LazyPageHeaderBtnScrollHere, LazyRichText, LazyRichTextReadMore, LazySkiAreaInfo, LazyTextWithPolaroid, LazyUspItems, LazyVacancies, LazyVacancy, LazyWeather, LazyWhitespace, LazyWinActie, LazyTemplate } from '#components'
const lazyGlobalComponents = [
  ["AccoSlider", LazyAccoSlider],
["AccoSliderSearch", LazyAccoSliderSearch],
["AlpendreamsContact", LazyAlpendreamsContact],
["BecomeAPartner", LazyBecomeAPartner],
["Breadcrumbs", LazyBreadcrumbs],
["Btn", LazyBtn],
["ContactInfo", LazyContactInfo],
["ContactMessageUs", LazyContactMessageUs],
["ContactMessageUsNEW", LazyContactMessageUsNEW],
["FaqRich", LazyFaqRich],
["Faqs", LazyFaqs],
["GoogleMaps", LazyGoogleMaps],
["Grid", LazyGrid],
["GroepshuizenGidsAanvragen", LazyGroepshuizenGidsAanvragen],
["HomeownerLeadFrom", LazyHomeownerLeadFrom],
["Iframe", LazyIframe],
["ImageGallery", LazyImageGallery],
["LinkBlocks", LazyLinkBlocks],
["NewsletterSubscribe", LazyNewsletterSubscribe],
["OurOffices", LazyOurOffices],
["OurPartners", LazyOurPartners],
["Page", LazyPage],
["PageHeader", LazyPageHeader],
["PageHeaderBtnScrollHere", LazyPageHeaderBtnScrollHere],
["RichText", LazyRichText],
["RichTextReadMore", LazyRichTextReadMore],
["SkiAreaInfo", LazySkiAreaInfo],
["TextWithPolaroid", LazyTextWithPolaroid],
["UspItems", LazyUspItems],
["Vacancies", LazyVacancies],
["Vacancy", LazyVacancy],
["Weather", LazyWeather],
["Whitespace", LazyWhitespace],
["WinActie", LazyWinActie],
["Template", LazyTemplate],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
